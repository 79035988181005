import { joinUrl } from '@grantstreet/psc-js/utils/urls.js'

export type GoogleAnalytics = {
    googleTagId: string
    userId: string
}

export type EmbeddedPublicSiteParams = {
    site: string
    rootIframeUrl: string
    elementSelector: string
    localTaxSysUrl?: string
    initialPath: string
    language: string
    googleAnalytics?: GoogleAnalytics
    onPathChange: (newPath: string) => void
    getJwt: () => string
    handleLogin: () => void
}

/**
 * Constructs the public site url to be used as the src in the embedded public
 * site iframe.
 *
 * @throws {Error} if the provided params do not include an initialPath
 * @returns string
 */
export function generateEmbeddedPublicSiteIframeUrl ({
  site,
  initialPath,
  rootIframeUrl,
}: Pick<EmbeddedPublicSiteParams, 'site' | 'initialPath' | 'rootIframeUrl'>): string {
  if (!site) {
    throw new Error('GsgPublicSite.attach requires a site argument.')
  }

  if (!initialPath) {
    throw new Error('GsgPublicSite.attach requires an initialPath argument.')
  }

  return joinUrl(rootIframeUrl, site, initialPath)
}
